import axios from 'axios';

const chatWithBotService = async (userInput) => {
    const apiEndpoint = '/chat';
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer YOUR_OPENAI_API_KEY`
    };

    const data = {
      content: userInput
    };

    try {
      const response = await axios.post(apiEndpoint, data, { headers });
      console.log(response);
      return response.data.content.trim();
    } catch (error) {
      console.error('Error communicating with the API:', error.message);
      return 'good for you!';
    }
  };


export {chatWithBotService};
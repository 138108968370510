import React, { useState } from 'react';
import './style.css';
import {chatWithBotService} from './api';
import Markdown from 'react-markdown';
import { Card, CardHeader, CardProps } from "@fluentui/react-components";


const Chat = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    const userMessage = { text: input, user: true };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    const aiMessage = { text: '...', user: false };
    setMessages((prevMessages) => [...prevMessages, aiMessage]);
    const response = await chatWithBotService(input);
    const newAiMessage = { text: response, user: false };
    setMessages((prevMessages) => [...prevMessages.slice(0, -1), newAiMessage]);
    setInput('');
  };

  return (
    <div className="chatbot-container">

    <div className="chatbot-messages">
    {messages.map((message, index) => (
      <Card>
      <div
      key={index}
      className={`message ${message.user ? 'user-message' : 'ai-message'}`}
      >
        <Markdown>{message.text}</Markdown>
      </div>
      </Card>
    ))}
    </div>
    <form className="chatbot-input-form" onSubmit={handleSubmit}>
    <input
    type="text"
    value={input}
    onChange={(e) => setInput(e.target.value)}
    placeholder="Type your message..."
    />
    <button type="submit">Send</button>
    </form>
    </div>
  );
};

export default Chat;
import React from 'react';
import './App.css';
import Chat from './pages/Chat';
import {
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
} from "@fluentui/react-components";

function App() {
  return (
    <div className="App">
        <Toolbar aria-label="Default">
    <Menu>
      <MenuTrigger>
        <ToolbarButton aria-label="More" />
      </MenuTrigger>
    </Menu>
    <ToolbarDivider />
     <h1>aWen</h1>
  </Toolbar>
     
      <Chat />
    </div>
  );
}

export default App;
